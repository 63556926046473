<template>
  <validation-observer ref="simpleRules">
    <div>
      <div class="card">
        <div class="nav-panel anan-tabs anan-tabs-line anan-tabs-normal anan-tabs-top">
          <div class="anan-tabs__nav">
            <div
              class="anan-tabs__nav-warp px-2"
              style="margin-top: 5px;"
            >
              <div
                class="anan-tabs__nav-tabs"
                style="transform: translateX(0px)"
              >
                <div
                  class="anan-tabs__nav-tab"
                  :class="{ active: isActive === 'all' }"
                  style="white-space: normal"
                  @click="getOrder(status = 0), isActive = 'all'"
                >
                  <span> {{ $t('all') }} </span>
                </div>

                <div
                  class="anan-tabs__nav-tab"
                  :class="{ active: isActive === 'not print' }"
                  style="white-space: normal"
                  @click="getOrder(status = 1), isActive = 'not print'"
                >
                  <span> {{ $t('notPrint') }} </span>
                </div>

                <div
                  class="anan-tabs__nav-tab"
                  :class="{ active: isActive === 'printed' }"
                  style="white-space: normal"
                  @click="getOrder(status = 2), isActive = 'printed'"
                >
                  <span> {{ $t('printed') }} </span>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div class="d-lg-flex justify-content-between align-items-center px-2">
          <h3 class="font-weight-bolder">
            {{ $t('printOrder') }}
          </h3>

          <div class="d-flex">
            <div class="income-order-search order-search w-100">
              <div class="anan-input search-input">
                <div class="anan-input__inner anan-input__inner--normal">
                  <input
                    v-model="orderId_text"
                    type="text"
                    :placeholder="$t('search')"
                    class="anan-input__input"
                    @input="getOrder(status)"
                  >
                  <div class="anan-input__suffix">
                    <i class="anan-input__clear-btn anan-icon" />
                    <i class="anan-input__suffix-icon anan-icon">
                      <i class="fal fa-search" />
                    </i>
                  </div>
                </div>
              </div>
            </div>

            <b-form-select
              v-model="selected"
              :options="options"
              class="w-50 ml-1"
              size="sm"
              @change="getOrder(status)"
            />
          </div>
        </div>
        <b-overlay
          :show="showOver"
          variant="transparent"
          opacity="0.99"
          blur="5px"
          rounded="sm"
        >
          <template #overlay>
            <div class="text-center">
              <p id="cancel-label">
                กรุณารอสักครู่...
              </p>
            </div>
          </template>
          <div class="pl-2 pr-2 pt-1">
            <b-table
              striped
              hover
              responsive
              :per-page="perPage"
              :items="OrderList"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              :filter-included-fields="filterOn"
              show-empty
            >
              <template #empty>
                <div class="text-center">
                  <img
                    src="@/assets/images/anan-img/empty/empty.png"
                    alt="empty"
                    width="180px"
                  >
                  <p>No Data</p>
                </div>
              </template>

              <template #cell(number)="data">
                <b-link :to="{name: 'admin-order-edit', params: {id: data.item._id}}">
                  {{ data.item._id }}
                </b-link>
                {{ time(data.item.created_at) }}
              </template>
              <template #cell(orderList)="data">
                <b-link :to="{name: 'admin-order-detail', params: {id: data.item._id}}">
                  ORD-{{ data.item.orderId_text }}
                  <!-- {{ data.item }} -->
                </b-link>
              </template>
              <template #cell(customer)="data">
                <b-link :to="{name: 'admin-employee-edit', params: {id: data.item.uid}}">
                  {{ data.item.username }}
                </b-link>
              </template>

              <template #cell(keyword)="data">
                {{ data.item.remark_admin ? data.item.remark_admin: '-' }}
              </template>
              <template #cell(amountStore)="data">
                {{ data.item.shops.length }}
              </template>
              <template #cell(deliveryType)="data">
                <span
                  v-if="data.item.delivery_type == 1"
                  class="badge bg-black"
                > {{ $t('truck') }}</span>
                <span
                  v-else
                  class="badge bg-danger"
                > {{ $t('ship') }}</span>
              </template>
              <template #cell(statusprint)="data">
                <span
                  v-if="data.item.prints"
                  class="badge badge-success badge-pill"
                > {{ $t('printed') }} </span>
                <span
                  v-else
                  class="badge badge-danger badge-pill"
                > {{ $t('notPrint') }} </span>
              </template>
              <template #cell(total)="data">
                {{ Commas(data.item.price_total) }} ฿
              </template>
              <template #cell(actions)="data">
                <feather-icon
                  icon="Edit3Icon"
                  class="mr-1 text-primary cursor-pointer"
                  @click="EditType(data.item)"
                />
              </template>
            </b-table>

            <div class="demo-spacing-0 d-flex justify-content-end mb-2">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
                @input="getOrder(status)"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </b-overlay>
      </div>

      <b-modal
        id="modal-add-new"
        title="เพิ่มรายการการเงิน"
        ok-title="บันทึก"
        cancel-title="ยกเลิก"
        @ok.prevent="submitAddNew()"
      >
        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Customer Code"
            rules="required"
          >
            <label for="customerCode">รหัสลูกค้า : <span class="text-danger">*</span></label>
            <b-form-input
              id="customerCode"
              v-model="customerCode"
              :state="errors.length > 0 ? false:null"
              type="text"
              placeholder="รหัสลูกค้า"
            />
            <span class="text-danger">{{ errors[0] }}</span>
          </validation-provider>
        </b-form-group>

        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Type"
            rules="required"
          >
            <label for="type">ประเภท : <span class="text-danger">*</span></label>
            <b-form-select
              v-model="type"
              :options="typeOp"
              :state="errors.length > 0 ? false:null"
            />
            <span class="text-danger">{{ errors[0] }}</span>
          </validation-provider>
        </b-form-group>

        <b-form-group>
          <label for="detail">รายละเอียด :</label>
          <b-form-textarea
            id="detail"
            v-model="detail"
            placeholder="รายละเอียด"
          />
        </b-form-group>

        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Amount"
            rules="required"
          >
            <label for="amount">จำนวน : <span class="text-danger">*</span></label>
            <b-form-input
              id="amount"
              v-model="amount"
              type="number"
              :state="errors.length > 0 ? false:null"
              placeholder="จำนวน"
            />
            <span class="text-danger">{{ errors[0] }}</span>
          </validation-provider>
        </b-form-group>

        <b-form-group>
          <label for="prefix">Prefix :</label>
          <b-form-input
            id="prefix"
            v-model="prefix"
            type="number"
            placeholder="Prefix"
          />
        </b-form-group>

        <b-form-group>
          <label for="prefixNum">Prefix Number :</label>
          <b-form-input
            id="prefixNum"
            v-model="prefixNum"
            type="number"
            placeholder="Prefix Number"
          />
        </b-form-group>
      </b-modal>
    </div>
  </validation-observer>
</template>

<script>
import {
  BTable, BLink, BFormSelect, BModal, BFormGroup, BFormInput, BFormTextarea, BPagination, BOverlay,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import moment from 'moment-timezone'

export default {
  components: {
    BTable,
    BLink,
    BFormSelect,
    BModal,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BPagination,
    BOverlay,
  },
  data() {
    return {
      isActive: 'all',
      required,
      selected: null,
      amount: 0,
      detail: '',
      type: null,
      customerCode: '',
      prefix: '',
      prefixNum: '',

      options: [
        { value: null, text: 'ทั้งหมด' },
        { value: 1, text: 'รถ' },
        { value: 2, text: 'เรือ' },
      ],
      currentPage: 1,
      perPage: 10,
      search: '',
      showOver: false,
      items: [],
      OrderList: [],
      status: 0,
      orderId_text: null,
    }
  },
  computed: {
    fields() {
      return [
        {
          key: 'number', label: this.$t('number'), thStyle: { width: '10%' }, thClass: 'text-center',
        },
        {
          key: 'orderList', label: this.$t('orderList'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'customer', label: this.$t('customer'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'keyword', label: this.$t('mnemonicPhraseAdmin'), sortable: false, thStyle: { width: '15%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'amountStore', label: this.$t('shopCount'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'total', label: this.$t('total'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center',
        },
        {
          key: 'deliveryType', label: this.$t('deliveryType'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'statusprint', label: this.$t('status'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'actions', label: this.$t('manage'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
      ]
    },
  },
  mounted() {
    this.getOrder(this.status)
  },
  methods: {
    time(data) {
      return moment(data).format('DD/MM/YYYY HH:mm:ss')
    },
    submitAddNew() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          const params = {
            customerCode: this.customerCode,
            type: this.type,
            detail: this.detail,
            amount: this.amount,
            prefix: this.prefix,
            prefixNum: this.prefixNum,
          }
          this.$http.post('groupbyuser/list/create', params).then(() => {
            this.getData(this.isActive)
            this.$bvToast.toast('เพิ่มข้อมูลสำเร็จ', {
              title: 'สำเร็จ',
              variant: 'success',
              solid: true,
              autoHideDelay: 1500,
            })
          })
          this.$bvModal.hide('modal-add-new')
        }
      })
    },
    showAddNew() {
      this.$bvModal.show('modal-add-new')
    },
    async getOrder(type) {
      this.showOver = true
      try {
        const params = {
          type,
          orderId_text: this.orderId_text,
          delivery: this.selected,
          page: this.currentPage,
          perPage: this.perPage,
        }
        const { data: res } = await this.$http.get('/order/OrderPrint', { params })
        this.OrderList = res.data
        this.totalRows = res.total
        this.showOver = false
        // console.log(this.OrderList)
        // console.log(res)
      } catch (e) {
        console.log(e)
        this.showOver = false
      }
    },
    EditType(data) {
      // eslint-disable-next-line no-underscore-dangle
      this.$router.push({ name: 'admin-order-edit', params: { id: data._id } })
    },
    DeleteData(data) {
      this.$bvModal.msgBoxConfirm('คุณต้องการลบข้อมูลใช่หรือไม่?', {
        title: 'ยืนยันการลบข้อมูล',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then(value => {
          if (value) {
            const params = {
              id: data.id,
            }
            this.$http.post('groupbyuser/list/delete', params).then(() => {
              this.getData(this.isActive)
              this.$bvToast.toast('ลบข้อมูลสำเร็จ', {
                title: 'สำเร็จ',
                variant: 'success',
                solid: true,
                autoHideDelay: 1500,
              })
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
}
</script>

          <style lang="scss" scoped></style>
